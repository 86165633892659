
// scss
import scssVars from '~/assets/scss/shared/_variables.scss';

// Vuex
import { mapState, mapActions } from 'vuex';

// Utils
import { scrollToWithBlackout, throttle, remToPx } from '@@/common/assets/js/utils/common-utils';

// Constants
import { menuItems } from '~/assets/js/constants/menuItems';

// Components
import VButton from 'landing/components/ui/button/VButton.vue';

const HEADER_ITEM_TEMPLATE = {
    developers: 'Девелоперам',
    buttons: {
        join: 'Вступить в клуб',
        schedule: 'Скачать расписание',
    },
};

export default {
    name: 'TheHeader',
    components: { VButton },

    props: {
        scheduleLink: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            lastScroll: 0,
            isTicking: false,
            throttleScroll: null,
            scrollY: 0,

            menuItems,

            text: HEADER_ITEM_TEMPLATE,


        };
    },

    computed: {

        ...mapState('header', [
            'isVisible',
            'isBurgerMenuVisible',
            'activeMenuItemId',
            'logoIcon',
            'partnerLogoIcon',
        ]),

        classList() {
            return {
                [this.$style._hidden]: !this.isVisible,
            };
        },

        scrollPercent() {
            if (typeof document === 'undefined') {
                return 0;
            }

            const documentElem = document.documentElement;

            return this.scrollY / (documentElem.scrollHeight - documentElem.clientHeight);
        },

        showFormButton() {
            if (typeof document === 'undefined') {
                return false;
            }

            const targetName = this.$route.name === 'index' ? 'home' : this.$route.name;
            const target = document.getElementById(`${targetName}-hero`);

            return this.scrollY > (target?.clientHeight || 0);
        },

        isOnHome() {
            return this.$route.path === '/';
        },

        currentForm() {
            const formDevelopers = 'home-form-developers';
            const homeForm = 'home-form';
            return this.isOnHome ? homeForm : formDevelopers;
        },

    },

    mounted() {
        // this.lastScroll = document.documentElement.scrollTop;

        this.throttleScroll = throttle(this.onScroll, 300);
        window.addEventListener('scroll', this.throttleScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.throttleScroll);

        this.throttleScroll = null;
    },

    methods: {
        ...mapActions('header', [
            'changeVisibility',
            'changeBurgerMenuState',
            'changeActiveMenuItemId',
        ]),

        onScroll() {
            this.scrollY = document.documentElement.scrollTop;

            // убрал скрывающийся хедер, полностью не стал вырезать на всякий случай
            // const isMoreMenuHeight = document.documentElement.scrollTop >= 200;
            //
            // if (isMoreMenuHeight) {
            //     if (document.documentElement.scrollTop > this.lastScroll) {
            //         if (this.isVisible) {
            //             this.changeVisibility(false);
            //         }
            //     } else if (!this.isVisible && this.lastScroll - document.documentElement.scrollTop > 20) {
            //         this.changeVisibility(true);
            //     }
            //     this.lastScroll = document.documentElement.scrollTop;
            // } else {
            //     this.changeVisibility(true);
            // }
        },

        handleClickLink(item) {
            if (!item.link && !this.isOnHome) {
                this.changeActiveMenuItemId(item.id);
                this.$router.push('/');
            } else {
                item.link ? this.changeActiveMenuItemId(item.id) : this.scrollToId(item);
            }
        },

        scrollToId(item) {
            const headerHeight = remToPx(scssVars[this.$deviceIs.pc ? 'header-h' : 'header-h-mobile']);
            const offset = item.id.includes('purpose') ? window.innerHeight * -1 : headerHeight;

            scrollToWithBlackout(item.id, offset);
            this.changeActiveMenuItemId(item.id);
        },

        scrollToForm() {
            scrollToWithBlackout(this.currentForm);
        },

        itemClassList(item) {
            return {
                [this.$style._active]: item.id === this.activeMenuItemId,
                [this.$style._developers]: item.label === HEADER_ITEM_TEMPLATE.developers,
            };
        },

    },
};
